import React from "react"
import { graphql } from "gatsby"
import Layout from "components/Layout/Layout"
import FeaturedLayout from "components/FeaturedLayout/FeaturedLayout"

const TwoColumnTemplate = (props) =>  {
	const data = props.data.wpPage
	return (
		<Layout 
    path={data.uri}
			seo={data.seo} 
		>
			<FeaturedLayout data={data} uri={data.uri} />
		</Layout>
	)
}

export const newsArticleQuery = graphql`
query($id: String!) {
  wpPage(id: {eq: $id}) {
    id
    title
    uri
    seo {
      title
      metaDesc
      metaKeywords
      breadcrumbs {
        text
        url
      }
    }
    template {
      ... on WpTwoColumnTemplate {
        ...twoColumnTemplateQuery
      }
    }
  }
}
`

export default TwoColumnTemplate